import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import userReducer from '../reducers/user';

export type RootState = ReturnType<typeof store.getState>;
const store = configureStore({
  reducer: {
    user: userReducer
  },
  middleware: (getDefaultMiddleware: any) => getDefaultMiddleware().concat(logger)
});

export type AppDispatch = typeof store.dispatch;

export default store;
